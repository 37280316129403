import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link, json } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import FoamTree from "@carrotsearch/foamtree";
import { axiosInstance } from "../../api/axiosInstance";
import { apiEndPoints } from "../../constant/apiEndPoints";
import style from "../Navbar/Navbar.module.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const FoamTreeVisualization = () => {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const foamTreeRef = useRef(null);

  const [dropdownExpanded, setDropdownExpanded] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchCompanies();
    const fetchCompanyData = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiEndPoints.getFoamDataByProfile}${profileId}`
        );

        console.log("Company Data:", JSON.stringify(response.data, null, 2));
        const companyData = response.data.data[0];

        if (foamTreeRef.current) {
          foamTreeRef.current.dispose();
          foamTreeRef.current = null;
        }

        addRandomColor(companyData?.group);

        console.log(
          "---jsonDattatata------",
          JSON.stringify(companyData, null, 2)
        );

        foamTreeRef.current = new FoamTree({
          id: "visualization",
          // layout: "ordered",
          layout: "squarified",
          dataObject: {
            groups: companyData.group.map((company, index) => ({
              label: companyData.companyName,
              groups: company.groups || [],
            })),
          },
          groupColorDecorator: function (opts, params, vars) {
            vars.groupColor = params.group.color;
            vars.labelColor = "auto";
          },
          fadeDuration: 700,
        });

        foamTreeRef.current.redraw();
      } catch (error) {
        console.error("Error fetching company data:", error);
        Swal.fire({
          icon: "error",
          title: "Error Fetching Company Data",
          text: error.message,
          timer: 4000,
          showConfirmButton: false,
          position: "top-center",
        });
      }
    };

    fetchCompanyData();

    return () => {
      const visualizationElement = document.getElementById("visualization");
      if (visualizationElement && foamTreeRef.current) {
        foamTreeRef.current.dispose();
        foamTreeRef.current = null;
      }
    };
  }, [profileId]);

  function generateRandomColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  function addRandomColor(node) {
    if (Array.isArray(node)) {
      node.forEach((item) => addRandomColor(item));
    } else if (node && typeof node === "object") {
      if (node.label) {
        node.color = generateRandomColor();
      }
      if (node.groups && Array.isArray(node.groups)) {
        node.groups.forEach((group) => addRandomColor(group));
      }
    }
  }

  const fetchCompanies = async () => {
    try {
      console.log("---fetchProfile---");

      const profile = await axiosInstance.post(
        apiEndPoints.getProfilesByUserId
      );

      console.log("---respone---", JSON.stringify(profile, null, 2));

      setCompanies(profile.data?.data);
    } catch (error) {
      console.error("Error fetching companies:", error.message);
    }
  };

  const toggleDropdown = () => {
    setDropdownExpanded(!dropdownExpanded);
  };

  const handleCompanyClick = (profileId) => {
    console.log("---frofileId----", profileId);
    navigate(`/profile/${profileId}`);
  };

  const handleCreateProfile = () => {
    navigate("/create-profile");
  };

  return (
    <div className={style.navbar}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item dropdown">
            <a
              className={`nav-link no-arrow ${style.circleIcon}`}
              href="#"
              role="button"
              onClick={toggleDropdown}
              aria-expanded={dropdownExpanded}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <img
                src="https://cdn.icon-icons.com/icons2/2719/PNG/512/dots_three_circle_vertical_icon_175231.png"
                alt="Menu"
                width="35"
                height="35"
              />
            </a>

            <ul
              className={`dropdown-menu dropdown-menu-end ${
                dropdownExpanded ? "show" : ""
              }`}
              style={{
                display: dropdownExpanded ? "block" : "none",
                right: 10,
              }}
            >
              {companies.length === 0 ? (
                <li className="dropdown-item">
                  <div className="loader">No Data</div>
                  <button
                    className="btn btn-primary mt-2"
                    onClick={handleCreateProfile}
                  >
                    Create Profile
                  </button>
                </li>
              ) : (
                companies?.map((company) => (
                  <li
                    key={company.id}
                    onClick={() => handleCompanyClick(company.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="dropdown-item">{company.companyName}</span>
                  </li>
                ))
              )}
              <>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item d-flex" href="/settings">
                    Settings
                  </a>
                </li>
              </>
            </ul>
          </li>
        </ul>
      </nav>
      <div
        id="visualization"
        style={{
          width: "100%",
          height: "95%",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        key="foamtree"
      >
        <h4>Please wait...</h4>
      </div>
    </div>
  );
};

export default FoamTreeVisualization;
