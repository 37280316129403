import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import Profile from "./Components/Profile/Profile";
import MultipleCompanies from "./Components/MultipleCompanies/MultipleComapnies";
import GetCompanies from "./Components/MultipleCompanies/GetMultipleCompanies";
import GetAllCompanies from "./Components/MultipleCompanies/GetAllCompanies";
import Navbar from "./Components/Navbar/Navbar";
import FoamTreeVisualization from "./Components/FoamTree/FoamTree";
import NoData from "./Components/NoData/NoData";
import CreateFoam from "./Components/FoamTree/CreateFoam";

function App() {
  const [userState, setUserState] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("token", token);
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/multipleCompanies" element={<MultipleCompanies />} />
          <Route path="/getCompanies" element={<GetCompanies />} />
          <Route path="/getAllCompanies" element={<GetAllCompanies />} />
          <Route
            path="/profile/:profileId"
            element={<FoamTreeVisualization />}
          />
          <Route path="/settings" element={<Navbar />} />
          <Route path="/noData" element={<NoData />} />
          <Route path="/createFoamTree" element={<CreateFoam />} />

          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Profile
                  setUserState={setUserState}
                  username={userState.fname}
                />
              ) : (
                <Navigate
                  to="/login"
                  state={{ message: "Please login to access this page" }}
                />
              )
            }
          />
          <Route
            path="/login"
            element={
              <Login
                setUserState={setUserState}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route path="/signup" element={<Register />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
