import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import style from "./Navbar.module.css";
import "bootstrap/dist/css/bootstrap.css"; // Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JS
import axios from "axios";
import { axiosInstance } from "../../api/axiosInstance";
import { apiEndPoints } from "../../constant/apiEndPoints";

const Navbar = () => {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [dropdownExpanded, setDropdownExpanded] = useState(false);
  const [companiesFetched, setCompaniesFetched] = useState(false);

  useEffect(() => {
    if (dropdownExpanded && !companiesFetched) {
      fetchCompanies();
    }
  }, [dropdownExpanded, companiesFetched]);

  const fetchCompanies = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in local storage");
      }

      const response = await axiosInstance.post(
        apiEndPoints.getProfilesByUserId
      );

      setCompanies(response.data.data);
      setCompaniesFetched(true);
    } catch (error) {
      console.error("Error fetching companies:", error.message);
      Swal.fire({
        icon: "error",
        title: "Error Fetching Companies",
        text: error.message,
        timer: 4000,
        showConfirmButton: false,
        position: "top-center",
      });
    }
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      Swal.fire({
        icon: "success",
        title: "Logout successful",
        showConfirmButton: false,
        timer: 2000,
      });
      navigate("/login");
    } catch (error) {
      console.error("Error:", error.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
        timer: 4000,
        showConfirmButton: false,
        position: "top-center",
      });
    }
  };

  const toggleDropdown = () => {
    setDropdownExpanded(!dropdownExpanded);
    if (!companiesFetched) {
      fetchCompanies();
    }
  };

  const handleCompanyClick = (profileId) => {
    navigate(`/profile/${profileId}`);
  };

  return (
    <div className={style.navbar}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand">C2glue</Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Companies
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/multipleCompanies">
                      Multiple Companies
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/getAllCompanies">
                      Get All Companies
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/getCompanies">
                      Get Companies
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item"></li>
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <button
                className="btn btn-outline-primary"
                onClick={handleLogout}
              >
                Logout
              </button>
              <li className="nav-item dropdown">
                <a
                  className={`nav-link no-arrow ${style.circleIcon}`}
                  href="#"
                  role="button"
                  onClick={toggleDropdown}
                  aria-expanded={dropdownExpanded}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 20,
                  }}
                >
                  <img
                    src="https://cdn.icon-icons.com/icons2/2719/PNG/512/dots_three_circle_vertical_icon_175231.png"
                    alt="Menu"
                    width="35"
                    height="35"
                  />
                </a>
                <ul
                  className={`dropdown-menu dropdown-menu-end ${
                    dropdownExpanded ? "show" : ""
                  }`}
                  style={{
                    display: dropdownExpanded ? "block" : "none",
                    right: 0,
                  }}
                >
                  {companies.length === 0 ? (
                    <li className="dropdown-item">
                      <div className="loader">Loading...</div>
                    </li>
                  ) : (
                    companies.map((company) => (
                      <li
                        key={company.id}
                        onClick={() => handleCompanyClick(company.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="dropdown-item">
                          {company.companyName}
                        </span>
                      </li>
                    ))
                  )}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item d-flex" href="/settings">
                      Settings
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
