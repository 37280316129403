import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import styles from '../MultipleCompanies/GetMultipleCompanies.module.css'; // Import your CSS file for styling
import appStyle from "./App.module.css";

const CompaniesTable = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found in local storage');
        }
        const response = await axios.post(`http://192.168.1.26:7000/Api/companies/getCompanies`, companies, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response.data);

        if (response.data.success) {
          setCompanies(response.data.data);
        } else {
          throw new Error(response.data.error || 'Failed to fetch companies');
        }
      } catch (error) {
        setError(error.message || 'Failed to fetch companies');
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []); // Empty dependency array ensures useEffect runs only once

  const handleView = () => {
    navigate('/createFoamTree');
  };

  const handleV = () => {
    navigate('/settings');
  };


  if (loading) {
    return <p className={styles.loadingText}>Loading...</p>;
  }

  if (error) {
    return <p className={styles.loadingText}>{error}</p>;
  }

  if (!Array.isArray(companies) || companies.length === 0) {
    return <p className={styles.noSurveysText}>No companies found for this user.</p>;
  }

  return (
    <div className={`${styles.tableContainer} ${appStyle.App}`}>
      <h1>Companies List</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Address</th>
            <th>Contact</th>
            <th></th> 
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.id}>
              <td>{company.companyName}</td>
              <td>{company.address}</td>
              <td>{company.contact}</td>
              <td>
                <button onClick={handleView}>Add Foam</button> 
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleV}>Back</button> 

    </div>
  );
};

export default CompaniesTable;
