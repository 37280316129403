import React from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Navbar = () => {
  const navigate = useNavigate();

  const handleView = () => {
    navigate("/settings");
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h4>Data not found</h4>
      <button
        style={{
          display: "flex",
          marginTop: "100px",
        }}
        onClick={handleView}
      >
        Go to settings
      </button>
    </div>
  );
};

export default Navbar;
