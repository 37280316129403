import axios from "axios";
import { enviornment } from "../constant/enviornment";

const configureAxios = () => {
  return axios.create({
    timeout: 8000,
    baseURL: enviornment.baseUrl,
    headers: {
      "content-Type": "application/json",
    },
  });
};

export const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const token = localStorage.getItem("token");

      console.log("-----token-----headr-0-----", token);
      if (token !== null && token !== undefined) {
        console.log("--token--", token);
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.log("--error--", error);
    }
    return config;
  },
  async (error) => {
    console.log("--error--", error);
    return await Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("--error--", error);
    return await Promise.reject(error);
  }
);
