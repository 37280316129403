import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import formStyles from "./MultipleCompanies.module.css";
import appStyle from "./App.module.css";
import { useNavigate } from "react-router-dom";

const CreateCompaniesForm = ({ userId }) => {
  const [companies, setCompanies] = useState([
    { companyName: "", address: "", contact: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newCompanies = [...companies];
    newCompanies[index][name] = value;
    setCompanies(newCompanies);
  };

  const handleAddCompany = () => {
    setCompanies([...companies, { companyName: "", address: "", contact: "" }]);
  };

  const handleRemoveCompany = (index) => {
    const newCompanies = companies.filter((_, i) => i !== index);
    setCompanies(newCompanies);
  };

  const validateForm = () => {
    for (let i = 0; i < companies.length; i++) {
      const { companyName, address, contact } = companies[i];
      if (!companyName || !address || !contact) {
        return `Company at index ${i + 1}: All fields are required`;
      }
    }
    return null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const error = validateForm();
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: error,
      });
      return;
    }

    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in local storage");
      }
      const response = await axios.post(
        `http://192.168.1.26:7000/Api/companies/createCompanies`,
        companies,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);

      Swal.fire({
        icon: "success",
        title: "Companies Created",
        text: "The companies have been successfully created.",
      });

      setCompanies([{ companyName: "", address: "", contact: "" }]);
    } catch (error) {
      console.error("Error creating companies:", error.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response ? error.response.data.error : error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleView = () => {
    navigate("/settings");
  };
  return (
    <div className={`${formStyles.formContainer} ${appStyle.App}`}>
      <form onSubmit={handleSubmit}>
        <h1>Create Companies</h1>
        {companies.map((company, index) => (
          <div key={index} className={formStyles.companyForm}>
            <input
              type="text"
              name="companyName"
              placeholder="Company Name"
              value={company.companyName}
              onChange={(event) => handleChange(index, event)}
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={company.address}
              onChange={(event) => handleChange(index, event)}
            />
            <input
              type="tel"
              name="contact"
              placeholder="Contact"
              value={company.contact}
              onChange={(event) => handleChange(index, event)}
              pattern="\d{0,10}"
              maxLength="10"
            />
            <button
              type="button"
              onClick={() => handleRemoveCompany(index)}
              disabled={companies.length === 1}
            >
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddCompany}>
          Add Another Company
        </button>
        <button type="submit" disabled={loading}>
          {loading ? "Creating..." : "Create Companies"}
        </button>
        <button type="button" onClick={handleView}>
          Back
        </button>
      </form>
    </div>
  );
};

export default CreateCompaniesForm;
