import React, { useState, useEffect } from "react";
import basestyle from "../Base.module.css";
import loginstyle from "./Login.module.css";
import axios from "axios";
import { useNavigate, NavLink, json } from "react-router-dom";
import Swal from "sweetalert2";
import appStyle from "./App.module.css";
import { axiosInstance } from "../../api/axiosInstance";
import { apiEndPoints } from "../../constant/apiEndPoints";

const Login = () => {
  const navigate = useNavigate();
  const [user, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/multipleCompanies");
    }
  }, [navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post(apiEndPoints.login, user);
      console.log("Response Data:", JSON.stringify(response.data, null, 2));

      const token = response.data?.token;
      console.log("Token:", token);
      localStorage.setItem("token", token);
      console.log(
        "Token saved to localStorage:",
        localStorage.getItem("token")
      );

      const userData = response.data?.data;
      console.log("userData:", userData);
      localStorage.setItem("userData", userData);
      console.log(
        "userData saved to localStorage:",
        localStorage.getItem("userData")
      );

      const companies = response.data?.companies;

      if (companies && companies?.length) {
        Swal.fire({
          icon: "success",
          title: "Login successful",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          navigate(`/profile/${companies[1].id}`);
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Login successful",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          navigate(`/nodata`);
        });
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data.error : error.message
      );
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response ? error.response.data.error : error.message,
        timer: 4000,
        showConfirmButton: false,
        position: "top-center",
      });
    }
    setLoading(false);
  };

  return (
    <div className="login-form">
      <div className={`${loginstyle.login} ${appStyle.App}`}>
        <form onSubmit={handleSubmit}>
          <h1>Login</h1>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            onChange={handleChange}
            value={user.email}
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            onChange={handleChange}
            value={user.password}
          />
          <div className="login-button">
            <button
              type="submit"
              className={basestyle.button_common}
              disabled={loading}
            >
              {loading ? "Loading..." : "Login"}
            </button>
          </div>
          <NavLink to="/signUp">SignUp</NavLink>
        </form>
      </div>
    </div>
  );
};

export default Login;
